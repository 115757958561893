<template>
  <div></div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations(["changeUid"]),
  },
  mounted() {
    this.changeUid(this.$route.query.uid);
    this.$router.replace({
      name: "Learning",
      params: { id: this.$route.query.id },
    });
  },
};
</script>

<style></style>
